@import '../../theme/styles';

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
    font-size: 14px;
}

.fc .fc-col-header-cell-cushion {
    color: var(--COLOR_LIGHT_FONT);
    text-decoration: none;
    font-weight: 400;
}

.fc .fc-toolbar-title {
    font-size: 14px;
    color: var(--COLOR_LIGHT_FONT);
}

.fc.fc-theme-standard .fc-toolbar .fc-button {
    color: var(--COLOR_LIGHT_FONT);
    background: var(--COLOR_BACKGROUND_SECONDARY);
    border: 0 none;
    border: 1px solid var(--COLOR_DARK_BORDER);
    font-size: 14px;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:focus,
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
    color: var(--COLOR_LIGHT_FONT);
    background: var(--COLOR_DARK_ACTIVE);
    border-color: var(--COLOR_DARK_BORDER);
}

.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
    opacity: 0.38;
    color: var(--COLOR_LIGHT_FONT);
    background: var(--COLOR_PRIMARY);
    border: 0 none;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: .785em;
}

:root {
    --fc-today-bg-color: var(--COLOR_BACKGROUND_DEFAULT);
    --fc-list-event-hover-bg-color: var(--COLOR_DARK_BORDER);
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: center;
}

.fc .fc-scroller {
    overflow: auto !important;
}

.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded,
.fc.fc-theme-standard .fc-view-harness th,
.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
    background: var(--COLOR_BACKGROUND_DEFAULT);
}

.fc.fc-theme-standard .fc-view-harness .fc-view {
    border-color: var(--COLOR_DARK_BORDER);
    background: var(--COLOR_BACKGROUND_DEFAULT);
}

.fc-direction-ltr .fc-list-day-text,
.fc-direction-rtl .fc-list-day-side-text,
.fc-direction-ltr .fc-list-day-side-text,
.fc-direction-rtl .fc-list-day-text {
    text-decoration: none;
    color: var(--COLOR_LIGHT_FONT);
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
    box-shadow: none;
    font-size: 14px;
    border-radius: 6px;
    margin: 0;
}

.fc-timegrid-event-harness {
    margin-left: 5px;
}

.fc-timegrid-event .fc-event-main {
    padding: 0px;
}

.fc-list-event-time {
    display: none;
}

.fc .fc-list-event-dot {
    border-radius: 3px;
    box-sizing: inherit;
    display: inline;
    height: 14px;
}

.fc .fc-list-day-cushion,
.fc .fc-list-table td {
    font-size: 14px;
    font-weight: 400;
}

.fc tbody,
.fc td,
.fc tfoot,
.fc th,
.fc thead,
.fc tr {
    height: 40px;
    font-size: 14px;
}

.fc td,
.fc th {
    vertical-align: inherit;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(172, 172, 172);
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}

.fc-newButton-button,
.fc-listButton-button {
    background-color: var(--COLOR_PRIMARY) !important;
    font-weight: 600 !important;
}

.fc-newButton-button:active,
.fc-listButton-button:active {
    background-color: var(--COLOR_PRIMARY_ACTIVE) !important;
}

.fc-newButton-button:hover,
.fc-listButton-button:hover {
    box-shadow: 0 0 0 0.05rem var(--COLOR_PRIMARY_ACTIVE) !important;
}