.form-check-input:checked {
    background-color: #FF5500;
    border-color: #FF5500;
}

.form-check-input {
    border-color: rgb(176, 176, 176);
    cursor: pointer;
}

.form-check .form-check-input {
    border-color: rgb(176, 176, 176);
    box-shadow: none;
}