.nav-tabs .nav-link {
    color: #fff;
    font-size: 13px;
    padding: 4px 12px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-top-color: #f68d2e;
    border-right-color: #f68d2e;
    border-left-color: #f68d2e;
}

.p-paginator,
.p-datatable .p-datatable-tbody>tr,
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-datatable-thead>tr>th {
    background: #2a323d;
}

.p-datatable .p-datatable-header {
    background: #2a323d;
}

.p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.6rem 0.5rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.p-datatable .p-datatable-loading-overlay {
    border-radius: 4px;
}

.p-datatable .p-column-header-content {
    padding: 0.2rem;
}

.p-paginator .p-paginator-current {
    font-size: 12px;
    border: none;
    color: #f3f3f3;
    height: 1rem;
    padding: 1rem;
}

.p-datatable .p-paginator-bottom {
    max-height: 3.2rem;
    border-width: 0 0 1px 0;
    border-radius: 4px;
}

.p-datatable .p-column-title {
    font-weight: 500;
    font-size: 12px;
    padding: 4px 0px;
}

.p-datatable .p-sortable-column:focus {
    box-shadow: none;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    border: none;
    color: #f3f3f3;
    min-width: 2rem;
    height: 2rem;
    margin: .143rem;
    border-radius: 50%;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    padding: 0.7rem;
}

.p-datatable-emptymessage {
    font-size: 12px;
}

.p-datatable .p-sortable-column.p-highlight {
    color: #fa9d6f;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #fa9d6f;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    color: #fa9d6f;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #fa9d6f;
}
