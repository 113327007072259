.form-control {
    resize: none;
}

.form-control:focus {
    border-color: #FF5500;
    box-shadow: 0 0 0 0.05rem #FF5500,
        0 0 0 0.1rem #FF550095;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }