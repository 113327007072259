@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.cdnfonts.com/css/amazon-ember');

body {
  font-family: 'Amazon Ember', 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding: 0;
  margin: 0;
}

.apexcharts-legend-text {
  color: white !important;
}

.pagination {
  --bs-pagination-color: #fff;
  --bs-pagination-focus-color: #fff;
  --bs-pagination-focus-bg: 0 0 0 0.1rem rgba(211, 105, 19, 0.397);
  --bs-pagination-focus-box-shadow: 0 0 0 0.1rem rgba(211, 105, 19, 0.397);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #FF5500;
  --bs-pagination-active-border-color: #FF5500;
}

.page-link {
  border-radius: 50% !important;
  margin: 0px 10px;
  height: 33px;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header {
  display: block;
}