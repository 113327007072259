:root {
    --COLOR_PRIMARY: #FF5500;
    --COLOR_PRIMARY_ACTIVE: #FF7733;
    --COLOR_GRAY: #ffffff;
    --COLOR_RED: #EF4444;
    --COLOR_BLUE: #3B82F6;
    --COLOR_PURPLE: #983ded;
    --COLOR_GREEN: #16A34A;
    --COLOR_DARKGRAY: #959595;
    --COLOR_BACKGROUND_DEFAULT: rgb(43, 48, 53);
    --COLOR_BACKGROUND_SECONDARY: rgb(47, 47, 47);
    --COLOR_LIGHT_FONT: rgba(255, 255, 255, 0.87);
    --COLOR_DARK_BORDER: rgba(255, 255, 255, 0.12);
    --COLOR_DARK_ACTIVE: rgb(28, 28, 28);
}